<template>
  <div class="tm-sliderPopularList__root">
    <div class="tm-sliderProductsList__swiperWrapper">
      <swiper
        :slides-per-view="1"
        :space-between="4"
        :modules="[Pagination]"
        :pagination="{
          clickable:true,
          el:'.popular-pagination'
        }"
        @swiper="sliderPopularBlockSwiper.setSliderRef"
      >
        <swiper-slide
          v-for="(product, idx) in flatsList"
          :key="`v-news_news-item-${idx}`"
          class="popular-slide"
        >
          <div class="swiper-slide">
            <SlideProductCard :flat="product" />
          </div>
        </swiper-slide>
      </swiper>
      <div class="tm-sliderProductsList__bottom">
        <div
          v-if="flatsList && flatsList?.length > 1"
          class="popular-block__controls"
        >
          <div class="slider-nav-count"><span>{{ activeIndex }}</span> — <span>{{ flatsList?.length }}</span></div>
          <div class="image-block__nav slider-nav">
            <button
              :class="{'disabled': sliderPopularBlockSliderRef?.isBeginning}"
              class="slider-nav-btns --prev"
              @click="sliderPopularBlockSwiper.prevSlide"
            />

            <button
              :class="{'disabled':sliderPopularBlockSliderRef?.isEnd}"
              class="slider-nav-btns --next"
              @click="sliderPopularBlockSwiper.nextSlide"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';

import type { Swiper as SwiperClass } from 'swiper/types';
import { Pagination } from 'swiper/modules';
import { setCustomSwiper } from '~/utils/swiper/setCustomSwiper';
import type { IProduct } from '#sitis/internal/controllers/products/models/Product';
import ShareComponent from '~/components/wigets/ShareComponent.vue';

const props = defineProps<{
	flatsList:IProduct[];
}>();

const sliderPopularBlockSliderRef = ref<SwiperClass | null>(null);
const sliderPopularBlockSwiper = setCustomSwiper(sliderPopularBlockSliderRef);

const activeIndex = computed(() => {
  if (sliderPopularBlockSliderRef.value) {
    return sliderPopularBlockSliderRef.value.activeIndex + 1;
  }
  return 1;
});

</script>

<style lang="scss" scoped>
@import "@/assets/scss/media";

.tm-sliderPopularList__root {
	& .tm-sliderProductsList__swiperWrapper {
		position: relative;

	}

	& .swiper-button-disabled {
		opacity: .2;
	}

	& .sliderProductsList-prev,
	& .sliderProductsList-next {
		top: initial;
		background: var(--white);
		bottom: -50px;
	}

	& .sliderProductsList-prev {
		left: 0;
	}

	& .sliderProductsList-next {
		right: 0;
	}

	& .sliderProductsList-pagination {
		justify-content: center;
		bottom: -30px;
		z-index: 1;
		display: flex;
		position: relative;

		& .swiper-pagination-bullet {
			height: 6px;
			width: 6px;
			background: var(--primary-60);
			border-radius: 50%;
			margin-right: 0;
		}
	}

	& .tm-sliderProductsList__bottom{
		margin-top: 32px;
		display: flex;
		justify-content: space-between;
		align-items: center;

	}
}

.popular-block__controls{
	display: flex;
	gap: 45px;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	&	.image-block__nav{
		gap: 20px;
	}

	.slider-nav-count{
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 42px; /* 175% */
		letter-spacing: -0.48px;
	}
}

@include media('md') {
	.tm-sliderPopularList__root{
		& .tm-sliderProductsList__bottom{
			margin-top: 16px;
		}
	}

	.popular-slide{
		height: auto;
	}
}
</style>
